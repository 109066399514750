import React from "react";
import Layout from "../components/layout"
import {getCurrentLocale} from "../helpers/fields";
import {forceHTTPS} from "../helpers/slugs";

class Homepage extends React.Component {

    render() {
        const locale = getCurrentLocale(this);
        const translations = this.props.pageContext.translations;

        forceHTTPS();

        return (
            <Layout lang={locale} className='landingspagina transparent' page={this} includeMenu={true} translations={translations}>
                <img className="woordbeeld" src="/img/woordbeeld.svg"/>
                <img className="logo" src="/img/logo.svg"/>
            </Layout>
        );
    }
}

export default Homepage;